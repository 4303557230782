import { enCommon, frCommon, svCommon } from './common';
export const en = {
  translation: {
    NoAccountWithThisEmailExists: 'No account with this phone number exists',
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Sign in to your account with your phone number',
    WeveSentAVerificationCodeToYourEmail:
      "We've sent a verification code to your phone number",
    VerifyEmail: 'Verify Phone Number',
    ...enCommon,
  },
};

export const fr = {
  translation: {
    NoAccountWithThisEmailExists:
      "Aucun compte avec ce numéro de téléphone n'existe",
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Connectez-vous à votre compte avec votre numéro de téléphone',
    WeveSentAVerificationCodeToYourEmail:
      'Nous avons envoyé un code de vérification à votre numéro de téléphone',
    VerifyEmail: 'Numéro de téléphone de vérification',
    ...frCommon,
  },
};

export const sv = {
  translation: {
    NoAccountWithThisEmailExists:
      'Det finns inget konto med detta telefonnummer',
    PleaseProvideYourEmailToReceivePaymentNotifications:
      'Logga in på ditt konto med ditt telefonnummer',
    WeveSentAVerificationCodeToYourEmail:
      'Vi har skickat en verifieringskod till ditt telefonnummer',
    VerifyEmail: 'Verifiera telefonnummer',
    ...svCommon,
  },
};
